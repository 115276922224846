import { Directive, ElementRef, Input, HostListener } from "@angular/core";

@Directive({
  selector: "[hiveMenuHover]",
})
export class MenuHoverDirective {
  private el: HTMLElement;

  constructor(el: ElementRef) {
    this.el = el.nativeElement;
  }

  @HostListener("mouseenter")
  onMouseEnter() {
    const children = this.el.children;
    if (children.length === 2 && children[1].nodeName === "UL") {
      const subMenu = children[1];
      subMenu.classList.remove("d-none");
      this.el.classList.add("hovered");
    }
  }
  @HostListener("mouseleave")
  onMouseLeave() {
    const children = this.el.children;
    if (children.length === 2 && children[1].nodeName === "UL") {
      const subMenu = children[1];
      subMenu.classList.add("d-none");
      this.el.classList.remove("hovered");
    }
  }
}
