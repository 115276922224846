import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import {
  OrganizationAddressComponent,
  OrganizationEmailComponent,
  OrganizationHoursComponent,
  OrganizationPhoneComponent,
  OrganizationLogoComponent,
  OrganizationMissionStatementComponent,
} from "./components";
import { CountToDirective } from "./directives/count-to.directive";
import { InViewPortDirective } from "./directives/in-view-port.directive";
import { PageTitleComponent } from "./components/page-title/page-title.component";
import { OrganizationConfigService } from "./services/organization-config.service";

@NgModule({
  declarations: [
    OrganizationAddressComponent,
    OrganizationEmailComponent,
    OrganizationHoursComponent,
    OrganizationPhoneComponent,
    OrganizationLogoComponent,
    OrganizationMissionStatementComponent,
    CountToDirective,
    InViewPortDirective,
    PageTitleComponent,
  ],
  exports: [
    OrganizationAddressComponent,
    OrganizationEmailComponent,
    OrganizationHoursComponent,
    OrganizationPhoneComponent,
    OrganizationLogoComponent,
    OrganizationMissionStatementComponent,
    CountToDirective,
    InViewPortDirective,
    PageTitleComponent,
  ],
  imports: [CommonModule],
  providers: [OrganizationConfigService],
})
export class SharedModule {}
