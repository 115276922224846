import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "hive-page-title",
  templateUrl: "./page-title.component.html",
  styleUrls: ["./page-title.component.scss"],
})
export class PageTitleComponent implements OnInit {
  @Input() text: string = "[The Hive]";

  constructor() {}

  ngOnInit() {}
}
