import { Component, OnInit, Input, ElementRef, HostListener } from "@angular/core";
import { NavbarMenu } from "src/app/core/models/navbar-menu.model";
import { NavigationService } from "src/app/core/services/navigation.service";

@Component({
  selector: "hive-navigation-bar",
  templateUrl: "./navigation-bar.component.html",
  styleUrls: ["./navigation-bar.component.scss"],
})
export class NavigationBarComponent implements OnInit {
  @Input() navbarMenus: NavbarMenu[];

  public sticky: boolean = false;

  constructor(private el: ElementRef, private navigationService: NavigationService) {}

  ngOnInit() {
    this.navbarMenus = this.navigationService.getNavigationItems();
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    this.sticky = window.pageYOffset > 40;
  }
}
