import { Component, OnInit, Input } from "@angular/core";
import { OrganizationConfigService } from "../services/organization-config.service";

@Component({
  selector: "hive-organization-hours",
  template: ` <span [class]="className">{{ hours }}</span> `,
  styles: [],
})
export class OrganizationHoursComponent implements OnInit {
  @Input() className: string = "hive-lighter";
  public hours: string;

  constructor(private configService: OrganizationConfigService) {}

  ngOnInit() {
    this.hours = this.configService.getHours();
  }
}
