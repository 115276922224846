import { Component, OnInit } from "@angular/core";
import { EventsService } from "../events/events.service";
import { UpcomingEvent } from "../events/upcoming-event";

@Component({
  selector: "hive-page-footer",
  templateUrl: "./page-footer.component.html",
  styleUrls: ["./page-footer.component.scss"],
})
export class PageFooterComponent implements OnInit {
  public copyrightYear: number;
  public events: UpcomingEvent[];
  constructor(private eventService: EventsService) {}

  ngOnInit() {
    this.copyrightYear = new Date().getFullYear();
    this.events = this.eventService.getEvents();
  }
}
