import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { NotFoundComponent } from "./not-found/not-found.component";

const routes: Routes = [
  {
    path: "home",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "about",
    loadChildren: () => import("./about/about.module").then((m) => m.AboutModule),
  },
  {
    path: "contact",
    loadChildren: () => import("./contact/contact.module").then((m) => m.ContactModule),
  },
  {
    path: "events",
    loadChildren: () => import("./events/events.module").then((m) => m.EventsModule),
  },
  {
    path: "meet-the-team",
    loadChildren: () =>
      import("./meet-the-team/meet-the-team.module").then((m) => m.MeetTheTeamModule),
  },
  {
    path: "help",
    loadChildren: () => import("./help/help.module").then((m) => m.HelpModule),
  },
  {
    path: "404",
    component: NotFoundComponent,
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "home",
  },
  {
    path: "**",
    redirectTo: "404",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
