import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrganizationConfigService {
  private address: string = '555 W. Glendale Ave Phoenix, AZ 85021';
  private email: string = 'dani@beyond-autism.com';
  private hours: string = 'Mon-Fri: 4:00PM-6:30PM';
  private phone: string = '602 540 8987';
  constructor() {}

  public getAddress(): string {
    return this.address;
  }
  public getEmail(): string {
    return this.email;
  }
  public getEmailHref(): string {
    return 'mailto:' + this.getEmail();
  }
  public getHours(): string {
    return this.hours;
  }
  public getPhone(): string {
    return this.phone;
  }
}
