import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { EventsRoutingModule } from "./events-routing.module";
import { UpcomingEventsComponent } from "./upcoming-events.component";
import { SharedModule } from "../shared/shared.module";
import { EventsService } from "./events.service";

@NgModule({
  declarations: [UpcomingEventsComponent],
  imports: [CommonModule, SharedModule, EventsRoutingModule],
  providers: [EventsService],
})
export class EventsModule {}
