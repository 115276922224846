import { Injectable } from "@angular/core";
import { Meta } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class SeoService {
  constructor(private meta: Meta) {}

  /*
   * Takes named arguments to dynamically set meta tags in the head of the document.
   * Can override with own configuration object.
   */
  public generateTags(config) {
    config = {
      title: "The Hive CRC",
      description: "",
      image: "",
      slug: "",
      ...config,
    };

    this.meta.updateTag({ name: "twitter:card", content: "summary" });
    this.meta.updateTag({ name: "twitter:site", content: "TheHiveCrc" });
    this.meta.updateTag({ name: "twitter:title", content: config.title });
    this.meta.updateTag({ name: "twitter:description", content: config.description });
    this.meta.updateTag({ name: "twitter:image", content: config.image });

    this.meta.updateTag({ property: "og:type", content: "article" });
    this.meta.updateTag({ property: "og:site_name", content: "TheHiveCrc" });
    this.meta.updateTag({ property: "og:title", content: config.title });
    this.meta.updateTag({ property: "og:description", content: config.description });
    this.meta.updateTag({ property: "og:image", content: config.image });
    this.meta.updateTag({ property: "og:url", content: `https:thehivecrc.com/${config.slug}` });
  }
}
