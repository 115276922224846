import { Component, OnInit, Input } from "@angular/core";
import { NavbarMenu } from "src/app/core/models/navbar-menu.model";
import { NavigationService } from "src/app/core/services/navigation.service";

@Component({
  selector: "hive-mobile-navigation-bar",
  templateUrl: "./mobile-navigation-bar.component.html",
  styleUrls: ["./mobile-navigation-bar.component.scss"],
})
export class MobileNavigationBarComponent implements OnInit {
  public showAside: boolean = false;
  @Input() navbarMenus: NavbarMenu[];
  constructor(private navigationService: NavigationService) {}

  ngOnInit() {
    this.navbarMenus = this.navigationService.getNavigationItems();
  }

  public toggleAside() {
    this.showAside = !this.showAside;
  }

  public onExpanderClick(menu: NavbarMenu) {
    menu.isExpanded = !menu.isExpanded;
  }

  public onMenuClick(menu: NavbarMenu) {
    this.toggleAside();
  }

  public onSubMenuClick(submenu: NavbarMenu) {
    this.toggleAside();
  }
}
