import { Component, OnInit } from "@angular/core";
import { trigger, state, transition, style, animate } from "@angular/animations";
import { ScrollService } from "./core/services/scroll.service";
import { NavigationService } from "./core/services/navigation.service";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "app-root",
  templateUrl: "./app.component.html",
  animations: [
    trigger("fadeOut", [
      // the "in" style determines the "resting" state of the element when it is visible.
      state("visible", style({ opacity: 0 })),
      state("hidden", style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(":enter", [style({ opacity: 1 }), animate(500)]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(":leave", animate(600, style({ opacity: 0 }))),
    ]),
  ],
})
export class AppComponent implements OnInit {
  constructor(private scrollService: ScrollService, private navigationService: NavigationService) {}

  ngOnInit(): void {}

  public onActivate(event) {
    this.scrollService.scrollToTopOfWindow();
  }
}
