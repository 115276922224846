import { Injectable } from "@angular/core";
import { UpcomingEvent } from "./upcoming-event";

@Injectable({
  providedIn: "root",
})
export class EventsService {
  constructor() {}

  public getEvents() {
    const event = new UpcomingEvent();
    event.title = "Life Skills - Kitchen Safety and Easy Meals Classes";
    event.date = "October 14 - November 25";
    event.datetime = "2019-10-14T16:30:00+00:00";
    event.registerLink = "https://forms.gle/gthjFceW1RjNmwRk6";
    event.imageUrl = "/assets/events/cooking-class-flyer.png";
    event.pdfUrl = "/assets/events/cooking-class-flyer.pdf";
    return [event];
  }
}
