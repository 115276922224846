<header class="page_header_side ds" [class.active-slide-side-header]="showAside">
  <span class="toggle_menu_side" (click)="toggleAside()">
    <span></span>
  </span>
  <div class="scrollbar-macosx">
    <div class="side_header_inner">
      <div class="pl-3">
        <a href="./" class="logo logo_with_text" aria-label="Navigate back to the home page of The Hive">
          <hive-organization-logo theme="light"></hive-organization-logo>
        </a>
      </div>
      <div class="header-side-menu darklinks">
        <!-- main side nav start -->
        <nav class="mainmenu_side_wrapper">
          <ul class="menu-side-click">
            <li *ngFor="let menu of navbarMenus" [ngClass]="{ expanded: menu.isExpanded }">
              <a routerLinkActive="active">
                <span [routerLink]="menu.route" (click)="onMenuClick(menu)">{{ menu.title }}</span>
                <span class="sub-menu-expander" *ngIf="menu.submenus.length > 0" (click)="onExpanderClick(menu)"></span>
              </a>
              <ul class="sub-menu">
                <li *ngFor="let submenu of menu.submenus">
                  <a [routerLink]="[menu.route, submenu.route]" (click)="onSubMenuClick(submenu)">{{
                    submenu.title
                  }}</a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
      <!-- <div class="side-header-social">
        <span>
          <a href="#" class="social-icon color-bg-icon rounded-icon socicon-facebook"></a>
          <a href="#" class="social-icon color-bg-icon rounded-icon socicon-twitter"></a>
          <a href="#" class="social-icon color-bg-icon rounded-icon socicon-googleplus"></a>
        </span>
      </div> -->
    </div>
  </div>
</header>
