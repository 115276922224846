import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "hive-organization-logo",
  styleUrls: ["./organization-logo.component.scss"],
  template: `
    <span [ngClass]="logo">
      <svg
        version="1.2"
        baseProfile="tiny"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 397 219.19"
        xml:space="preserve"
        [ngClass]="shadow"
        [ngStyle]="{ 'height.px': heightOfLogo }"
      >
        <path
          class="text"
          fill="#003B53"
          d="M104.33,92.79c0-1.81,0.01-3.63,0.01-5.44c0.37-5.45,0.61-10.9-0.01-16.34c0-1.77,0-3.55,0-5.32
   c-0.02-2.67,0.18-5.35-0.12-8c-0.32-2.82-0.39-5.52,1.67-7.98c5.31-3.79,11.24-4.69,17.84-2.39c3.42,1.19,5,3.19,4.99,6.28
   c-0.08,16.79-1.54,33.54-2.36,50.3c-0.26,5.43-0.04,10.88-0.04,16.63c1.44-1.21,2.86-2.26,4.06-3.44
   c4.94-4.88,18.04-5.01,23.44,1.71c5.4,6.72,8.29,14.11,9.94,21.79c2.26,10.57,6.12,20.73,10.81,30.73c1.07,2.29,1.91,4.66,2.73,7.01
   c1.23,3.52,0.04,6.56-3.54,8.45c-3.63,1.92-7.53,2.05-11.41,0.39c-2.98-1.28-4.65-3.5-6.16-5.78c-6.9-10.4-10.21-21.71-12.96-33.14
   c-0.55-2.3-1.22-4.58-2.04-7.65c-2.46,5.16-3.29,9.89-4.32,14.52c-2.54,11.46-4.75,22.96-5.32,34.62c-0.18,3.65-2.15,6.63-6.62,8.12
   c-4.62,1.54-8.62,0.42-12.19-2.08c-3.88-4.85-3.67-10.56-5.28-15.88c-0.35-1.73-0.7-3.47-1.05-5.2c-0.68-5.52-1.37-11.04-2.05-16.56
   c0.19-1.75,0.3-3.5-0.25-5.23c-0.19-5.56-0.38-11.12-0.58-16.68c-0.01-1.71-0.02-3.42-0.03-5.13c0.02-5.56,0.03-11.12,0.05-16.68
   c0.04-1.74,0.07-3.48,0.11-5.23C104.28,103.73,104.73,98.27,104.33,92.79z"
        />
        <path
          class="text"
          fill="#003B53"
          d="M261.97,192.49c1.01-6.11,2.12-12.21,3.02-18.33c3.37-22.69,6.39-45.42,14.27-67.49
   c1.98-5.54,7.74-8.4,13.96-7.01c5.37,1.2,8.37,6.3,6.81,11.56c-3.46,11.73-6.99,23.46-9.26,35.39c-2.48,12.99-4.67,26-7.27,38.97
   c-1.41,7.04-2.41,14.19-6.33,20.76c-2.4,4.01-5.54,7.58-10.12,10.2c-7.48,4.29-15.46,3.32-20.56-2.79
   c-6.48-7.76-9.38-16.69-12.12-25.61c-6.16-20.03-9.38-40.49-13.65-60.79c-0.68-3.22-0.89-6.43-3.62-9.27
   c-2.18-2.27-1.55-5.12,0.19-7.6c3.85-5.51,16.2-9.32,22.83-0.79c4.81,6.19,5.71,13.19,7.2,20.14
   C251.77,150.74,254.78,171.85,261.97,192.49z"
        />
        <path
          class="text"
          fill="#003B53"
          d="M355.3,197.84c-15.74,0.06-25.38-4.91-32.96-12.7c-7.06-7.25-10.81-15.66-12.07-24.74
   c-0.22-1.6-0.91-2.72-2.23-3.91c-4.28-3.82-6.46-8.31-6-13.5c0.32-3.58,2.27-6.4,6.52-7.81c1.76-0.58,2.19-1.51,2.77-2.78
   c4.5-9.99,13.08-16.88,25.8-19.97c13.9-3.38,30.14,3.55,37.17,15.39c6.16,10.37,1.81,21.5-11.33,28.75
   c-6.86,3.79-14.6,5.78-22.85,6.47c-2.09,0.17-2.8,0.41-2.18,2.22c1.5,4.36,3.26,8.58,7.36,11.9c5.26,4.26,10.9,4.9,17.51,2.12
   c4.15-1.75,7.3-4.3,9.95-7.32c2.52-2.87,6.23-4.06,10.4-4.07c7.49-0.02,11.91,6.03,8.87,12.1c-0.78,1.56-1.89,3.14-3.33,4.34
   C378.86,192.59,366.5,196.65,355.3,197.84z M336.01,141.1c0,0.75,0.16,1.52-0.03,2.24c-0.78,3.05,1.21,3.4,4.24,2.92
   c3.99-0.64,7.7-1.76,11.03-3.68c3.08-1.77,4.99-3.94,3.91-7.08c-1.05-3.05-6.6-6.5-10.34-6.26c-4,0.26-6.6,2.23-7.88,5.19
   C336.03,136.57,336.03,138.85,336.01,141.1z"
        />
        <path
          class="text"
          fill="#003B53"
          d="M193.11,46.76c7.43-4.19,12.93-3.08,16.71,3.29c1.19,2.01,2.25,4.16,2.74,6.34
   c1.69,7.48,4.41,14.71,7.57,21.86c1.68,3.8,0.83,5.94-2.42,7.17c-3.48,1.31-7.02,0.19-9.32-3.09c-3.77-5.38-5.58-11.29-7.12-17.26
   c-0.15-0.58-0.33-1.15-0.85-1.72c-2.38,5.23-2.9,10.69-3.87,16.08c-0.38,2.11-0.55,4.25-0.76,6.38c-0.31,3.15-2.29,5.07-5.71,5.23
   c-3.8,0.18-6.14-1.45-6.99-4.27c-1.42-4.68-2.26-9.45-2.75-14.24c-1.13-11.1-1.19-22.23-0.58-33.35c0.11-2.05-0.39-2.65-2.99-2.36
   c-4.24,0.49-8.52,0.84-12.8,1.07c-2.21,0.12-3,0.71-2.94,2.52c0.64,19.02,0.87,38.06,3.74,56.99c0.22,1.48,0.41,3.01,0.21,4.48
   c-0.36,2.64-2.32,4.4-5.69,4.6c-3.34,0.2-5.89-1.17-6.49-3.82c-1-4.41-1.74-8.87-2.27-13.33c-1.88-15.97-3.23-31.97-3.96-48.01
   c-0.08-1.85-0.72-2.39-2.97-2.2c-3.87,0.32-7.77,0.47-11.65,0.66c-2.19,0.11-4.29-0.16-6.12-1.19c-2.04-1.15-2.92-2.79-2.68-4.73
   c0.24-1.93,1.93-3.01,4.03-3.65c5.01-1.55,10.31-1.37,15.52-1.34c3.06,0.02,3.83-0.67,3.74-3.05c-0.23-5.99-0.1-11.99-0.07-17.99
   c0.02-4.2,2.97-6.71,7.68-6.62c4.63,0.09,7.28,2.44,7.26,6.55c-0.03,5.89-0.05,11.77-0.25,17.66c-0.06,1.69,0.51,2.3,2.66,2.09
   c4.8-0.47,9.6-0.93,14.41-1.23c1.97-0.12,2.08-0.97,2.03-2.13c-0.16-3.31-0.21-6.63-0.46-9.93c-0.31-4,2.47-6.47,7.62-6.57
   c4.68-0.09,7.14,1.97,7.08,6.05c-0.04,2.89-0.08,5.78-0.36,8.66c-0.2,2.05,0.5,2.92,3.25,2.51c1.97-0.29,4.01-0.44,6.01-0.41
   c4.19,0.07,6.78,1.83,6.99,4.56c0.21,2.79-2.39,5.16-6.35,5.61c-2.79,0.32-5.6,0.56-8.41,0.65c-1.8,0.06-2.31,0.64-2.27,1.97
   c0.06,1.6-0.1,3.21-0.13,4.81C193.08,43.52,193.11,45.01,193.11,46.76z"
        />
        <path
          fill="#E7A93B"
          d="M103.55,114.41c-0.02,5.56-0.03,11.12-0.05,16.68c-30.47-0.01-60.94-0.04-91.41,0.01
   c-4.68,0.01-8.09-1.48-10.05-4.77c-1.89-3.17-1.29-6.27,1.51-8.98c2.41-2.34,5.8-2.98,9.4-2.98
   C43.14,114.37,73.35,114.39,103.55,114.41z"
        />
        <path
          class="text"
          fill="#003B53"
          d="M186.75,160.64c-0.03-7.91-0.22-15.81-0.69-23.71c-0.31-5.36,4.63-10.02,10.69-10.19
   c5.83-0.16,10.91,4.21,10.89,9.6c-0.08,18.63,2.42,37.07,6.22,55.42c0.95,4.6-3.42,9.46-9.37,10.43c-7.24,1.18-14.16-1.9-15.3-7.06
   c-2-9.07-2.06-18.27-2.43-27.44C186.67,165.35,186.75,162.99,186.75,160.64z"
        />
        <path
          class="text"
          fill="#003B53"
          d="M246.98,91.05c-6.15-0.12-11.76-3.31-16.06-8.17c-2.25-2.54-3.85-5.37-4.68-8.42
   c-0.76-2.78-1.56-5.38-3.74-7.87c-3.34-3.8-2.27-7.27,2.3-10.23c1.07-0.69,1.14-1.63,1.65-2.47c3.46-5.64,8.84-9.52,16.83-10.14
   c6.8-0.53,12.19,2.07,16.12,6.42c5.75,6.35,4.2,13.46-3.84,18.24c-3.28,1.95-6.99,3.13-11.04,3.59c-2.21,0.25-3.41,0.68-2.21,2.83
   c1.47,2.62,2.99,5.19,6.76,5.87c4.04,0.73,6.85-1.24,9.3-3.5c0.35-0.32,0.64-0.68,0.98-1.01c2.68-2.55,6.69-3.19,9.62-1.54
   c2.95,1.66,3.43,4.81,1.18,7.74C266.81,86.76,256.06,91.08,246.98,91.05z M240.6,59.94c-0.99,2.56,1.13,2.34,3.64,1.56
   c1.22-0.38,2.42-0.87,3.51-1.45c1.55-0.82,2.57-1.98,1.72-3.51c-0.85-1.53-2.36-2.59-4.59-2.65c-2.24-0.06-3.37,1.14-3.91,2.64
   C240.65,57.44,240.74,58.43,240.6,59.94z"
        />
        <path
          fill="#E7A93B"
          d="M104.33,92.79c0.4,5.48-0.05,10.94-0.67,16.39c-23.37,0.02-46.73,0.06-70.1,0.04
   c-5.74-0.01-9.67-3.1-9.97-7.52c-0.33-4.94,3.12-8.4,9.05-8.89c2.39-0.2,4.83-0.12,7.24-0.12C61.37,92.72,82.85,92.76,104.33,92.79z
   "
        />
        <path
          fill="#E7A93B"
          d="M103.53,136.21c0.19,5.56,0.38,11.12,0.58,16.68c-23.11,0.01-46.22,0.03-69.32,0.02
   c-6.88,0-11.21-3.2-11.33-8.22c-0.11-4.96,4.59-8.47,11.55-8.48C57.85,136.19,80.69,136.21,103.53,136.21z"
        />
        <path
          fill="#E7A93B"
          d="M104.36,158.12c0.68,5.52,1.37,11.04,2.05,16.56c-16.13,0.02-32.26,0.09-48.39,0.04
   c-6.14-0.02-9.98-3.26-10.04-8.11c-0.06-5.02,3.82-8.49,10-8.53C73.44,158.01,88.9,158.1,104.36,158.12z"
        />
        <path
          fill="#E7A93B"
          d="M104.34,71c0.61,5.45,0.37,10.9,0.01,16.34C88.9,87.36,73.45,87.39,58,87.36
   c-6.09-0.01-9.89-2.98-10.02-7.68c-0.15-5.11,3.51-8.74,9.62-8.78C73.18,70.8,88.76,70.95,104.34,71z"
        />
        <path
          fill="#E7A93B"
          d="M107.46,179.88c1.61,5.33,1.39,11.04,5.28,15.88c-10.98,0.04-21.97,0.15-32.95,0.08
   c-5.2-0.03-8.07-3.01-8.07-7.87c0-5.09,3.01-7.98,8.81-8.05C89.5,179.81,98.48,179.88,107.46,179.88z"
        />
        <path
          fill="#E7A93B"
          d="M105.89,49.7c-2.06,2.46-2,5.16-1.67,7.98c0.3,2.65,0.1,5.34,0.12,8c-7.53,0.04-15.06,0.23-22.58,0.08
   c-6.41-0.13-9.58-3.13-9.45-8.36c0.12-5.02,3.45-7.7,9.79-7.74C90.03,49.62,97.96,49.68,105.89,49.7z"
        />
        <path
          class="text"
          fill="#003B53"
          d="M207.08,107.43c-0.22,6.04-4.59,10-10.85,9.83c-6.31-0.17-10.73-4.64-10.44-10.57
   c0.25-5.17,4.72-8.61,11.06-8.5C202.76,98.31,207.26,102.37,207.08,107.43z"
        />
      </svg>
    </span>
  `,
})
export class OrganizationLogoComponent implements OnInit {
  @Input() theme: string = "default";
  @Input() shadow: string = "light-shadow";
  @Input() heightOfLogo: number = 70;

  public logo = {
    logo: true,
    light: false,
    default: true,
  };
  constructor() {}

  ngOnInit() {
    this.logo.default = this.theme === "default";
    this.logo.light = this.theme === "light";
  }
}
