<div class="navbar-top-container">
  <nav class="site-header sticky-top page_header header_white"
    [ngClass]="{ 'fixed-navbar': sticky, 'pt-0 pb-1': sticky, 'pt-3': !sticky }">
    <div class="row d-flex flex-column flex-md-row justify-content-around align-items-center">
      <ul class="main-menu nav hover-menu">
        <li>
          <a href="#" class="navbar-logo" [ngClass]="{ 'py-1': sticky, 'py-0': !sticky }"
            aria-label="Navigate back to the home page of The Hive">
            <hive-organization-logo shadow="no-shadow"></hive-organization-logo>
          </a>
        </li>
        <li *ngFor="let menu of navbarMenus" class="menu-item" hiveMenuHover>
          <a [routerLink]="menu.route" routerLinkActive="active">
            {{ menu.title }}
          </a>
          <ul style="position: absolute" [ngClass]="{ 'sub-menu d-none': menu.submenus.length > 0 }">
            <li *ngFor="let submenu of menu.submenus">
              <a [routerLink]="[menu.route, submenu.route]">{{ submenu.title }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</div>
