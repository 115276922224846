import { Component, OnInit, Input } from "@angular/core";
import { OrganizationConfigService } from "../services/organization-config.service";

@Component({
  selector: "hive-organization-email",
  template: ` <a [class]="className" href="{{ emailHref }}">{{ emailDisplay }}</a> `,
  styles: [],
})
export class OrganizationEmailComponent implements OnInit {
  @Input() className: string = "hive-lighter";

  public emailDisplay: string;
  public emailHref: string;

  constructor(private configService: OrganizationConfigService) {}

  ngOnInit() {
    this.emailDisplay = this.configService.getEmail();
    this.emailHref = this.configService.getEmailHref();
  }
}
