import { Injectable, ElementRef } from "@angular/core";
import { ViewportScroller } from "@angular/common";

@Injectable()
export class ScrollService {
  constructor(private viewportScroller: ViewportScroller) {}

  private anchorToScrollTo: string;

  public scrollToTopOfWindow() {
    window.scrollTo(0, 0);
  }

  public setAnchorToScrollTo(anchor: string) {
    this.anchorToScrollTo = anchor;
  }

  public getAnchorToScrollTo() {
    return this.anchorToScrollTo;
  }

  public scrollToAnchor(anchor: string) {
    this.viewportScroller.setOffset([0, 100]);
    this.viewportScroller.scrollToAnchor(anchor);
  }

  public isElementWithinViewport(el: ElementRef, threshold: number = 0): boolean {
    const componentPosition = el.nativeElement.offsetTop;
    const scrollPosition = window.pageYOffset;
    const viewPortHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    if (scrollPosition + viewPortHeight + threshold >= componentPosition) {
      return true;
    }
    return false;
  }
}
