<footer class="page_footer template_footer ds ms parallax overlay_color pt-5 pb-5">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div id="subscribe"
          class="widget widget_mailchimp with_padding cs main_color2 parallax overlay_color topmargin_0">
          <h3 class="widget-title">Our Newsletter</h3>
          <p>Coming Soon!</p>
          <!-- <form class="signup" action="./" method="get">
            <div class="form-group-wrap">
              <div class="form-group">
                <label for="mailchimp" class="sr-only">Enter your email here</label>
                <input
                  name="email"
                  type="email"
                  id="mailchimp"
                  class="mailchimp_email form-control text-center"
                  placeholder="Your Email Address"
                />
              </div>
              <button type="submit" class="theme_button color2">Subscribe now</button>
            </div>
            <div class="response"></div>
          </form> -->
        </div>
      </div>
      <div class="col-md-4 col-sm-6 col-xs-12 col-md-pull-4 text-center">
        <div class="widget widget_text">
          <h3 class="widget-title mb-3">Contact Us</h3>
          <ul class="list-unstyled greylinks">
            <li class="media d-inline">
              <i class="fa fa-map-marker hive-primary pr-2 mt-2" aria-hidden="true"></i>
              <hive-organization-address className="hive-light"></hive-organization-address>
            </li>
            <li class="media d-inline">
              <i class="fa fa-phone hive-primary pr-2 mt-2" aria-hidden="true"></i>
              <hive-organization-phone className="hive-light"></hive-organization-phone>
            </li>
            <li class="media d-inline">
              <i class="fa fa-pencil hive-primary pr-2 mt-2" aria-hidden="true"></i>
              <hive-organization-email className="hive-light"></hive-organization-email>
            </li>
            <li class="media d-inline">
              <i class="fa fa-clock-o hive-primary pr-2 mt-2" aria-hidden="true"></i>
              <hive-organization-hours className="hive-light"></hive-organization-hours>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-4 col-sm-6 col-xs-12 col-md-push-4 text-center">
        <div class="widget widget_text widget_about">
          <div class="logo logo_with_text mb-1">
            <hive-organization-logo theme="light"></hive-organization-logo>
          </div>
          <p>
            <hive-organization-mission-statement></hive-organization-mission-statement>
          </p>
          <!-- <p class="mt-2">
            <a
              class="social-icon border-icon rounded-icon socicon-facebook"
              href="#"
              title="Facebook"
            ></a>
            <a
              class="social-icon border-icon rounded-icon socicon-twitter"
              href="#"
              title="Twitter"
            ></a>
            <a
              class="social-icon border-icon rounded-icon socicon-google"
              href="#"
              title="Google Plus"
            ></a>
            <a
              class="social-icon border-icon rounded-icon socicon-linkedin"
              href="#"
              title="Linkedin"
            ></a>
            <a
              class="social-icon border-icon rounded-icon socicon-youtube"
              href="#"
              title="Youtube"
            ></a>
          </p> -->
        </div>
      </div>
      <div class="col-md-4 col-sm-6 col-xs-12 hidden-xs hidden-sm text-center">
        <div class="widget widget_recent_posts">
          <h3 class="widget-title">Latest News</h3>
          <ul class="list-unstyled greylinks">
            <li class="media d-inline">
              <p *ngFor="let event of events">
                <a [routerLink]="['/events/upcoming']"> {{event.title}}. {{event.date}}</a>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
<section class="ds ms parallax page_copyright overlay_color">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center py-4">
        <p>&copy; Copyright {{ copyrightYear }}. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</section>
