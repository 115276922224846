import { NgModule, Optional, SkipSelf } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { ScrollService } from "./services/scroll.service";
import { NavigationService } from "./services/navigation.service";
import { PageTitle } from "./services/page-title.service";
import { SeoService } from "./services/seo.service";
import { throwIfAlreadyLoaded } from "../shared/guards/import.guard";
@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  exports: [],
  providers: [ScrollService, NavigationService, PageTitle, SeoService],
})
export class CoreModule {
  /**
   *
   */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
