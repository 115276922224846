import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hive-organization-mission-statement',
  template: `
    <span>
      Helping people with disabilities find endless empowerment through career, creative, and
      community outlets.
    </span>
  `,
  styles: []
})
export class OrganizationMissionStatementComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
