import { Component, OnInit, Input } from "@angular/core";
import { OrganizationConfigService } from "../services/organization-config.service";

@Component({
  selector: "hive-organization-phone",
  template: ` <span [class]="className">{{ phone }}</span> `,
  styles: [
    `
      .reversed {
        unicode-bidi: bidi-override;
        direction: rtl;
      }
    `,
  ],
})
export class OrganizationPhoneComponent implements OnInit {
  @Input() className: string = "hive-lighter";
  public phone: string;

  constructor(private configService: OrganizationConfigService) {}

  ngOnInit() {
    this.phone = this.configService.getPhone().split("").reverse().join("");
    this.className = this.className + " reversed";
  }
}
