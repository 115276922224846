<section class="hive-dark-bg-darken10 hive-lighter-color page_topline">
  <div class="container-fluid px-5 py-1">
    <div class="row">
      <div class="col-lg text-center text-lg-left hidden-xs">
        <div class="inline-content big-spacing">
          <!-- <div class="page_social">
            <a class="social-icon socicon-facebook" href="#" title="Facebook"></a>
            <a class="social-icon socicon-twitter" href="#" title="Twitter"></a>
            <a class="social-icon socicon-google" href="#" title="Google Plus"></a>
            <a class="social-icon socicon-linkedin" href="#" title="Linkedin"></a>
            <a class="social-icon socicon-youtube" href="#" title="Youtube"></a>
          </div> -->
          <span class="xs-block">
            <i class="fa fa-clock-o hive-primary pr-1" aria-hidden="true"></i>
            <hive-organization-hours></hive-organization-hours>
          </span>
        </div>
      </div>
      <div class="col-lg text-center text-lg-right">
        <div>
          <div id="topline-hide" class="inline-content big-spacing">
            <span class="hidden-xs">
              <i class="fa fa-map-marker hive-primary pr-1" aria-hidden="true"></i>
              <hive-organization-address></hive-organization-address>
            </span>
            <span class="hive-lighter-color hidden-xs">
              <i class="fa fa-pencil hive-primary pr-1" aria-hidden="true"></i>
              <hive-organization-email></hive-organization-email>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
