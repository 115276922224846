<div class="d-flex justify-content-center">
  <!-- template sections -->
  <section>
    <div class="container">
      <div class="row align-items-center">
        <div class="col text-center">
          <p class="not-found"><span class="hive-primary">404</span></p>
          <h1 class="hive-darker">Oops, page not found!</h1>
          <p><a href="./" class="theme_button color1 min_width_button">Back to homepage</a></p>
        </div>
      </div>
    </div>
  </section>
</div>
