import { Component, OnInit } from "@angular/core";

@Component({
  selector: "hive-info-bar",
  templateUrl: "./info-bar.component.html",
  styleUrls: ["./info-bar.component.scss"],
})
export class InfoBarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
