export class NavbarMenu {
  public route: string;
  public title: string;
  public isRoutable: boolean;
  public isExpanded: boolean;
  public submenus: Array<NavbarMenu> = [];

  constructor(
    route: string,
    title: string,
    submenus: Array<NavbarMenu> = [],
    isRoutable: boolean = true
  ) {
    this.route = route;
    this.title = title;
    this.submenus = submenus;
    this.isRoutable = isRoutable;
  }
}
