import { Component, OnInit } from "@angular/core";
import { EventsService } from "./events.service";
import { UpcomingEvent } from "./upcoming-event";

@Component({
  selector: "hive-upcoming-events",
  templateUrl: "./upcoming-events.component.html",
  styles: [],
})
export class UpcomingEventsComponent implements OnInit {
  public events: UpcomingEvent[];
  constructor(private eventService: EventsService) {}

  ngOnInit() {
    this.events = this.eventService.getEvents();
  }
}
