<hive-page-title text="Upcoming Events"></hive-page-title>
<section class="ls pt-2 pb-5 mt-2" *ngFor="let event of events;">
  <div class="container">
    <div class="row">
      <div class="side-item side-md content-padding big-padding with_border bottom_color_border left">
        <div class="row">
          <div class="col-md-7 col-lg-7">
            <div class="item-media entry-thumbnail">
              <a href="{{event.pdfUrl}}" [attr.aria-label]=event.title>
                <img loading="lazy" width="300" height="420" src="{{event.imageUrl}}"
                  class="align-self-top mr-3 img-thumbnail img-fluid" alt="{{event.title}}" />
              </a>
            </div>
          </div>
          <div class="col-md-5 col-lg-5">
            <div class="item-content">
              <header class="entry-header">
                <h3 class="entry-title"> <a href="{{event.registerLink}}" rel="bookmark">{{event.title}}</a> </h3>
                <div class="entry-meta inline-content greylinks"> <span>
                    <i class="fa fa-calendar highlight pr-2" aria-hidden="true"></i>
                    <span>
                      <time [attr.datetime]="event.datetime">
                        {{event.date}}</time>
                    </span>
                  </span> <span>
                    <i class="fa fa-user highlight pr-2" aria-hidden="true"></i>
                    <span>Dani</span>
                  </span>
                  <!-- <span class="categories-links">
                    <i class="fa fa-tags highlight rightpadding_5" aria-hidden="true"></i>
                    <a href="blog-right.html">Community</a>
                  </span> -->
                </div>
              </header>
              <div class="entry-content">
                <p>
                  Click <a href="{{event.registerLink}}">here</a> to register.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
