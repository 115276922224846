import { Injectable } from "@angular/core";
import { NavbarMenu } from "src/app/core/models/navbar-menu.model";

@Injectable()
export class NavigationService {
  private navbarMenus = [
    new NavbarMenu("home", "HOME"),
    new NavbarMenu(
      "about",
      "ABOUT US",
      [new NavbarMenu("mission", "Our Mission"), new NavbarMenu("costs", "Costs and Funding")],
      false
    ),
    new NavbarMenu("events/upcoming", "EVENTS"),
    // new NavbarMenu('shop', 'SHOP'),
    // new NavbarMenu('services', 'SERVICES'),
    new NavbarMenu("meet-the-team/the-team", "MEET THE TEAM"),
    new NavbarMenu("help", "HELP US", [
      new NavbarMenu("donate", "Donate"),
      // new NavbarMenu('volunteer', 'Volunteer')
    ]),
    new NavbarMenu("contact/us", "CONTACT US"),
  ];

  constructor() {}

  public getNavigationItems(): NavbarMenu[] {
    return this.navbarMenus;
  }
}
