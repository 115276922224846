import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NgbAlertModule, NgbCarouselModule } from "@ng-bootstrap/ng-bootstrap";

import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { NotFoundComponent } from "./not-found/not-found.component";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { PageFooterModule } from "./page-footer/page-footer.module";
import { NavigationModule } from "./navigation/navigation.module";
import { InfoBarComponent } from "./info-bar/info-bar.component";

@NgModule({
  declarations: [AppComponent, NotFoundComponent, InfoBarComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,

    NgbAlertModule,
    NgbCarouselModule,

    CoreModule,
    NavigationModule,
    PageFooterModule,
    SharedModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
