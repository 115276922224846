import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";

/**
 * Service responsible for setting the title that appears above the component pages.
 */
@Injectable()
export class PageTitle {
  private _title = "";

  get title(): string {
    return this._title;
  }

  set title(title: string) {
    this._title = title;
    if (title !== "") {
      title = `${title} | `;
    }
    this.bodyTitle.setTitle(`${title} The Hive - A Community Resource Center`);
  }

  constructor(private bodyTitle: Title) {}
}
