import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { PageFooterComponent } from "./page-footer.component";
import { RouterModule } from "@angular/router";
import { EventsModule } from "../events/events.module";

@NgModule({
  declarations: [PageFooterComponent],
  exports: [PageFooterComponent],
  imports: [CommonModule, SharedModule, RouterModule, EventsModule],
})
export class PageFooterModule {}
