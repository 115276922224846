import { Component, OnInit, Input } from "@angular/core";
import { OrganizationConfigService } from "../services/organization-config.service";

@Component({
  selector: "hive-organization-address",
  template: `
    <span [class]="className">
      {{ address }}
    </span>
  `,
  styles: [],
})
export class OrganizationAddressComponent implements OnInit {
  @Input() className: string = "hive-lighter";

  public address: string;
  constructor(private orgConfigService: OrganizationConfigService) {}

  ngOnInit() {
    this.address = this.orgConfigService.getAddress();
  }
}
