import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavigationBarComponent } from "./navigation-bar/navigation-bar.component";
import { MobileNavigationBarComponent } from "./mobile-navigation-bar/mobile-navigation-bar.component";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";
import { MenuHoverDirective } from "./directives/menu-hover.directive";

@NgModule({
  declarations: [NavigationBarComponent, MobileNavigationBarComponent, MenuHoverDirective],
  exports: [NavigationBarComponent, MobileNavigationBarComponent],
  imports: [CommonModule, RouterModule, SharedModule],
})
export class NavigationModule {}
